import React, { useState } from 'react'
import "./PlayAudio.css";
import PlayAudioPopup from './PlayAudioPopup'
const PlayAudio = ({ sourceId, caption }) => {

    const [isAudioPlay, setIsAudioPlay] = useState(false);
    const toggleAudioPlay = () => {
        setIsAudioPlay(!isAudioPlay);
    };

    return (
        <div className="playaudio">
            <img src={require('../../../Images/chat-sending-audio-image.png')} alt="test"  onClick={toggleAudioPlay} />
            {isAudioPlay && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <PlayAudioPopup sourceId={sourceId} toggleAudioPlay={toggleAudioPlay} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlayAudio