
const PlayImagePopup = ({ sourceId, toggleImageDisplay }) => {
    return (
        <>
            

            <input type="button" class="popup_close" value="X" onClick={toggleImageDisplay}></input>
            <div className="playimagepopup_img_root"><img className="playimagepopup_img" src={sourceId} alt="" /></div>
        </>
    );
}
export default PlayImagePopup