import React, { useEffect, useState } from "react";
import "./AsyncImage.css";
import PlayImagePopup from "./PlayImagePopup"
const AsyncImage = ({ imgId, caption }) => {

    const [isImageDisplay, setisImageDisplay] = useState(false);
    const toggleImageDisplay = () => {
        setisImageDisplay(!isImageDisplay);
    };


    const [img, setImg] = useState();
    const fetchImage = async () => {
        setImg('https://wabadev.bulkwise.in/images/' + imgId);
    };
    useEffect(() => {
        // fetchImage();
    }, []);
    return (
        <div className="asyncimageouter">
            <img src={'https://wabadev.bulkwise.in/images/' + imgId} alt="icons" onClick={toggleImageDisplay} />
            {/*<div>{caption === '' ? <div></div> : <p>{caption}</p>}</div>*/}
            {isImageDisplay && (
                <div className="playimagepopup_background">
                    <div className="playimagepopupscreen">
                        <PlayImagePopup sourceId={'https://wabadev.bulkwise.in/images/' + imgId} toggleImageDisplay={toggleImageDisplay} />
                    </div>
                </div>
            )}
        </div>

         
    );
}
export default AsyncImage