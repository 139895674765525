import React, { useState, useEffect } from 'react'
import "./UpdateProfile.css";
import axios from 'axios'
const UpdateProfile = ({ togglePopupUpdateProfile, selectid, selectaccesstoken, selectBusinessaddress, selectDescription, selectVertical, selectAbout, selectUemail, selectWebsite1, selectWebsite2 }) => {
    const [businessaddress, setBusinessaddress] = useState("");
    const [about, setAbout] = useState("");
    const [description, setDescription] = useState("");
    const [vertical, setVertical] = useState("");
    const [email, setEmail] = useState("");
    const [website1, setWebsite1] = useState("");
    const [website2, setWebsite2] = useState("");
    const [error, setError] = useState("");
    const [IsError, setIsError] = useState(false);

    const handleChange = (event) => {
        setVertical(event.target.value);
    };

    useEffect(() => {
        setBusinessaddress(selectBusinessaddress);
        setAbout(selectAbout);
        setDescription(selectDescription);
        setVertical(selectVertical);
        setEmail(selectUemail)
        setWebsite1(selectWebsite1);
        setWebsite2(selectWebsite2);
    }, []);

    const updateProfile = async () => {
        const input = {
            accesstoken: selectaccesstoken,
            id: selectid,
            businessaddress: businessaddress,
            about: about,
            description: description,
            vertical: vertical,
            email: email,
            website1: website1,
            website2: website2
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/account/updateprofile', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                   // console.log("RESPONSE RECEIVED:==sadfasdfasfasfdf= ", res.data.data);
                    //setAccountInfo(res.data.data);
                    //setAccountInfo([res.data.data, ...accountInfo]);
                    //console.log("RESPONSE RECEIVED:=== sadfasdfasdfa", accountInfo);
                } 
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }

    const handleSubmit = (e) => {
        if (businessaddress === '') {
            e.preventDefault();
            setError("Enter your business address");
            setIsError(true);
        } else if (about === '') {
            e.preventDefault();
            setError("Enter your about");
            setIsError(true);
        } else if (description === '') {
            e.preventDefault();
            setError("Enter your description");
           setIsError(true);
        } else if (vertical === '') {
            e.preventDefault();
            setError("Enter your category");
            setIsError(true);
        } else if (email === '') {
            e.preventDefault();
            setError("Enter your email Id");
            setIsError(true);
        } else {
            e.preventDefault();
             
            updateProfile();
        }
    }


    return (
        <>
            <div className="updateprofile_header">
                <div className="updateprofile_header_sub">
                    <span className="updateprofile_header_title">Update Profile</span>
                </div>
            </div>
            <div className="updateprofile_main">
                <div>
                    <span className="updateprofile_content">Specify your physical business address</span>
                </div>
                <div>
                    <input type="text"
                        maxLength={256}
                        name="businessaddress"
                        id='businessaddress'
                        className='updateprofile_input'
                        value={businessaddress}
                        placeholder='Business address'
                        onChange={e => setBusinessaddress(e.target.value)}
                    />
                </div>
                <div>
                    <span className="updateprofile_content">Add your About Content</span>
                </div>
                <div>
                    <input type="text"
                        minLength={5}
                        name="about"
                        id='about'
                        className='updateprofile_input'
                        value={about}
                        placeholder='About'
                        onChange={e => setAbout(e.target.value)}
                    />
                </div>
                <div>
                    <span className="updateprofile_content">Business description</span>
                </div>
                <div>
                    <input type="text"
                        minLength={5}
                        name="description"
                        id='description'
                        className='updateprofile_input'
                        value={description}
                        placeholder='Business description'
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>
                <div>
                    <span className="updateprofile_content">Specify your business category</span>
                </div>
                <div>
                    <select className="updateprofile_select" onChange={handleChange}>
                        <option value="AUTO">AUTO</option>
                        <option value="BEAUTY">BEAUTY</option>
                        <option value="APPAREL">APPAREL</option>
                        <option value="EDU">EDU</option>
                        <option value="ENTERTAIN">ENTERTAIN</option>
                        <option value="EVENT_PLAN">EVENT_PLAN</option>
                        <option value="FINANCE">FINANCE</option>
                        <option value="GROCERY">GROCERY</option>
                        <option value="GOVT">GOVT</option>
                        <option value="HOTEL">HOTEL</option>
                        <option value="HEALTH">HEALTH</option>
                        <option value="NONPROFIT">NONPROFIT</option>
                        <option value="PROF_SERVICES">PROF_SERVICES</option>
                        <option value="RETAIL">RETAIL</option>
                        <option value="TRAVEL">TRAVEL</option>
                        <option value="RESTAURANT">RESTAURANT</option>
                        <option value="OTHER">OTHER</option>
                    </select>
                </div>
                <div>
                    <span className="updateprofile_content">Add your business email address</span>
                </div>
                <div>
                    <input type="text"
                        minLength={5}
                        name="email"
                        id='email'
                        className='updateprofile_input'
                        value={email}
                        placeholder='Business email'
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <span className="updateprofile_content">Add your business websites</span>
                </div>
                <div>
                    <input type="text"
                        name="website1"
                        id='website1'
                        className='updateprofile_input'
                        value={website1}
                        placeholder='Business website'
                        onChange={e => setWebsite1(e.target.value)}
                    />
                </div>
                <div>
                    <span className="updateprofile_content">Add your business websites</span>
                </div>
                <div>
                    <input type="text"
                        name="website2"
                        id='website2'
                        className='updateprofile_input'
                        value={website2}
                        placeholder='Business website'
                        onChange={e => setWebsite2(e.target.value)}
                    />
                </div>
                <div>
                    <button className='updateprofile_button' onClick={handleSubmit}>Update</button>
                </div>

                <div hidden={!IsError}><span className="chatslogin_right_error">{error}</span></div>
            </div>
        </>
    )
}
export default UpdateProfile