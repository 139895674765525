import React, { useState } from "react";
import axios from 'axios'
import "./AsyncUploadImage.css";
const AsyncUploadImage = ({ togglePopupImage, socket }) => {
    var singleFileObj = [];
    var singleFileArray = [];
    const [singleFileObjValue, setSingleFileObjValue] = useState([]);
    const [singleFileArrayValue, setSingleFileArrayValue] = useState([]);
    const onImageChange = event => {
        for (let i = 0; i < event.target.files.length; i++) {
            singleFileObj.push(event.target.files[i]);
            singleFileArray.push(URL.createObjectURL(event.target.files[i]));
        }
        setSingleFileObjValue(singleFileObj);
        setSingleFileArrayValue(singleFileArray);
    };
    const shoot = async () => {
        for (let i = 0; i < singleFileObjValue.length; i++) {
            let formData = new FormData();
            formData.append('file', singleFileObjValue[i]);
            formData.append('messaging_product', 'whatsapp');
            try {
                let res = await axios.post('https://graph.facebook.com/v20.0/'+ localStorage.getItem("id") +'/media', formData,
                    {
                        headers: {

                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem("accesstoken")
                        },
                    }

                );
                if (res.status === 200) {
                    socket.emit("media",
                        {
                            to: localStorage.getItem("selectedNumber"),
                            name: localStorage.getItem("selectedName"),
                            id: res.data.id,
                            phid: localStorage.getItem("id"),
                            type: 'image',
                            caption: ''
                        }
                    )
                }
            } catch (error) {
                console.error('Error uploading img : ', error);
            }
        }
        togglePopupImage();
    }
    const removeImage = (index) => {
        setSingleFileArrayValue([
            ...singleFileArrayValue.slice(0, index),
            ...singleFileArrayValue.slice(index + 1, singleFileArrayValue.length)
        ]);

        setSingleFileObjValue([
            ...singleFileObjValue.slice(0, index),
            ...singleFileObjValue.slice(index + 1, singleFileObjValue.length)
        ]);
    };
    return (

        <div>
            <div className="asyncuploadimagescreenheader">
                <p>Select Image</p>
                <span onClick={togglePopupImage}>X</span>
            </div>
            <div className="asyncuploadimagescreensend">
                <input type="file" name="myImage" multiple accept="image/png, image/jpeg" onChange={onImageChange} />
                <button onClick={shoot}>Send</button>
            </div>
            <div className="asyncuploadimagescreen">
                {singleFileArrayValue.length !== 0 &&
                    singleFileArrayValue.map((url, index) => (
                        <div key={index} >
                            <div className="asyncuploadimage_img_background">
                                <img className="asyncuploadimage_img_content" src={url} alt="..." key={index} />
                            </div>
                            <span className="asyncuploadimage_remove_img" onClick={() => removeImage(index)}  >
                                X
                            </span>
                        </div>

                    ))}
            </div>
        </div>

    );
}
export default AsyncUploadImage