// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */
    .markus-select {
        width: 15vh;
        height: 3vh;
        margin-top: .8vh;
        margin-right: 1vh;
        float: right;
        border-radius: 2px;
    }
}

@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .markus-select {
        width: 20vh;
        height: 5vh;
        margin-top: 2.5vh;
        margin-left: auto;
        margin-right: 2.5vh;
        border-radius: 2px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/MarkUs.css"],"names":[],"mappings":";AACA,2BAA2B,iDAAiD;IACxE;QACI,WAAW;QACX,WAAW;QACX,gBAAgB;QAChB,iBAAiB;QACjB,YAAY;QACZ,kBAAkB;IACtB;AACJ;;AAEA,2BAA2B,2EAA2E;IAClG;QACI,WAAW;QACX,WAAW;QACX,iBAAiB;QACjB,iBAAiB;QACjB,mBAAmB;QACnB,kBAAkB;IACtB;AACJ","sourcesContent":["\r\n@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */\r\n    .markus-select {\r\n        width: 15vh;\r\n        height: 3vh;\r\n        margin-top: .8vh;\r\n        margin-right: 1vh;\r\n        float: right;\r\n        border-radius: 2px;\r\n    }\r\n}\r\n\r\n@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */\r\n    .markus-select {\r\n        width: 20vh;\r\n        height: 5vh;\r\n        margin-top: 2.5vh;\r\n        margin-left: auto;\r\n        margin-right: 2.5vh;\r\n        border-radius: 2px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
