// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Images/search15.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.seacrch_bar_user_main {
    background-color: white;
    display: inline;
}





@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */


    .seacrch_bar_user {
        width: 98vw;
        box-sizing: border-box;
        border: 2px solid #E9EAEC;
        border-radius: 5px;
        font-size: 4vw;
        background-color: #F0F2F9;
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        background-position: 5px 5px;
        background-repeat: no-repeat;
        padding: 4px 4px 4px 40px;
        color: #54656F;
        margin : 1vw;
    }
}

@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */



    .seacrch_bar_user {
        width: 24vw;
        box-sizing: border-box;
        border: 2px solid #E9EAEC;
        border-radius: 5px;
        font-size: 1vw;
        background-color: #F0F2F9;
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        background-position: 5px 5px;
        background-repeat: no-repeat;
        padding: 4px 4px 4px 40px;
        color: #54656F;
        margin-left: .3vw;
        margin-top:.1vw;
        margin-bottom:0vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/SearchBar.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,eAAe;AACnB;;;;;;AAMA,2BAA2B,iDAAiD;;;IAGxE;QACI,WAAW;QACX,sBAAsB;QACtB,yBAAyB;QACzB,kBAAkB;QAClB,cAAc;QACd,yBAAyB;QACzB,yDAAqD;QACrD,4BAA4B;QAC5B,4BAA4B;QAC5B,yBAAyB;QACzB,cAAc;QACd,YAAY;IAChB;AACJ;;AAEA,2BAA2B,2EAA2E;;;;IAIlG;QACI,WAAW;QACX,sBAAsB;QACtB,yBAAyB;QACzB,kBAAkB;QAClB,cAAc;QACd,yBAAyB;QACzB,yDAAqD;QACrD,4BAA4B;QAC5B,4BAA4B;QAC5B,yBAAyB;QACzB,cAAc;QACd,iBAAiB;QACjB,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":["\r\n.seacrch_bar_user_main {\r\n    background-color: white;\r\n    display: inline;\r\n}\r\n\r\n\r\n\r\n\r\n\r\n@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */\r\n\r\n\r\n    .seacrch_bar_user {\r\n        width: 98vw;\r\n        box-sizing: border-box;\r\n        border: 2px solid #E9EAEC;\r\n        border-radius: 5px;\r\n        font-size: 4vw;\r\n        background-color: #F0F2F9;\r\n        background-image: url('../../../Images/search15.png');\r\n        background-position: 5px 5px;\r\n        background-repeat: no-repeat;\r\n        padding: 4px 4px 4px 40px;\r\n        color: #54656F;\r\n        margin : 1vw;\r\n    }\r\n}\r\n\r\n@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */\r\n\r\n\r\n\r\n    .seacrch_bar_user {\r\n        width: 24vw;\r\n        box-sizing: border-box;\r\n        border: 2px solid #E9EAEC;\r\n        border-radius: 5px;\r\n        font-size: 1vw;\r\n        background-color: #F0F2F9;\r\n        background-image: url('../../../Images/search15.png');\r\n        background-position: 5px 5px;\r\n        background-repeat: no-repeat;\r\n        padding: 4px 4px 4px 40px;\r\n        color: #54656F;\r\n        margin-left: .3vw;\r\n        margin-top:.1vw;\r\n        margin-bottom:0vw;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
