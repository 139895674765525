// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */
    .switchaccount-select {
        width: 98vw;
        height: 3.5vh;
        margin: 1vw;
        border: 2px solid #E9EAEC;
        border-radius: 5px;
        font-size: 3vw;
    }
}

@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .switchaccount-select {
        width: 24vw;
        height: 4vh;
        margin: .5vw;
        border: 2px solid #E9EAEC;
        border-radius: 5px;
        font-size: 1vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/SwitchAccount.css"],"names":[],"mappings":";AACA,2BAA2B,iDAAiD;IACxE;QACI,WAAW;QACX,aAAa;QACb,WAAW;QACX,yBAAyB;QACzB,kBAAkB;QAClB,cAAc;IAClB;AACJ;;AAEA,2BAA2B,2EAA2E;IAClG;QACI,WAAW;QACX,WAAW;QACX,YAAY;QACZ,yBAAyB;QACzB,kBAAkB;QAClB,cAAc;IAClB;AACJ","sourcesContent":["\r\n@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */\r\n    .switchaccount-select {\r\n        width: 98vw;\r\n        height: 3.5vh;\r\n        margin: 1vw;\r\n        border: 2px solid #E9EAEC;\r\n        border-radius: 5px;\r\n        font-size: 3vw;\r\n    }\r\n}\r\n\r\n@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */\r\n    .switchaccount-select {\r\n        width: 24vw;\r\n        height: 4vh;\r\n        margin: .5vw;\r\n        border: 2px solid #E9EAEC;\r\n        border-radius: 5px;\r\n        font-size: 1vw;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
