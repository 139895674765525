import React, { useEffect, useState } from "react";
import "./AsyncDocument.css";
const AsyncDocument = ({ sourceId, caption }) => {
    const [source, setSource] = useState();
    const fetchImage = async () => {
        setSource('https://wabadev.bulkwise.in/documents/' + sourceId);
    };
    useEffect(() => {
        fetchImage();
    }, []);
    return (
        <div className="asyncdocumentouter">
            <a href={source} target="_blank">
                <img className="asyncdocumentouter_image" src={require('../../../Images/chat-sending-doc-image.png')} alt="test"  />
            </a>
        </div>
    );
}
export default AsyncDocument