import React, { useState } from "react";
import axios from 'axios'
import "./AsyncUploadDocument.css";
const AsyncUploadDocument = ({ togglePopupDocument, socket }) => {
    var singleFileObj = [];
    var singleFileArray = [];
    const [singleFileObjValue, setSingleFileObjValue] = useState([]);
    const [singleFileArrayValue, setSingleFileArrayValue] = useState([]);
    const onDocumentChange = event => {
        for (let i = 0; i < event.target.files.length; i++) {
            singleFileObj.push(event.target.files[i]);
            singleFileArray.push(URL.createObjectURL(event.target.files[i]));
        }
        setSingleFileObjValue(singleFileObj);
        setSingleFileArrayValue(singleFileArray);
    };
    const shoot = async () => {
        for (let i = 0; i < singleFileObjValue.length; i++) {
            let formData = new FormData();
            formData.append('file', singleFileObjValue[i]);
            formData.append('messaging_product', 'whatsapp');
            try {
                let res = await axios.post('https://graph.facebook.com/v20.0/' + localStorage.getItem("id") + '/media', formData,
                    {
                        headers: {

                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem("accesstoken")
                        },
                    }

                );
                if (res.status === 200) {
                    socket.emit("media",
                        {
                            to: localStorage.getItem("selectedNumber"),
                            name: localStorage.getItem("selectedName"),
                            id: res.data.id,
                            phid: localStorage.getItem("id"),
                            type: 'document',
                            caption: ''
                        }
                    )
                }
            } catch (error) {
                console.error('Error uploading img : ', error);
            }
        }
        togglePopupDocument();
    }
    const removeImage = (index) => {
        setSingleFileArrayValue([
            ...singleFileArrayValue.slice(0, index),
            ...singleFileArrayValue.slice(index + 1, singleFileArrayValue.length)
        ]);

        setSingleFileObjValue([
            ...singleFileObjValue.slice(0, index),
            ...singleFileObjValue.slice(index + 1, singleFileObjValue.length)
        ]);
    };
    return (
        <div>
            <div className="asyncuploaddocumentscreenheader">
                <p>Select Document</p>
                <span onClick={togglePopupDocument}>X</span>
            </div>
            <div className="asyncuploaddocumentscreensend">
                <input type="file" name="myImage" multiple accept="application/pdf" onChange={onDocumentChange} />
                <button onClick={shoot}>Send</button>
            </div>
            <div className="asyncuploaddocumentscreen">
                {singleFileArrayValue.length !== 0 &&
                    singleFileArrayValue.map((url, index) => (
                        <div key={index} >
                            <div className="asyncuploaddocument_img_background">
                                <a  href={url} alt="..." key={index} >
                                    <img className="asyncuploaddocument_img_content" alt="test"></img>
                                    {/*{url}*/}</a>
                            </div>
                            <span className="asyncuploaddocument_remove_img" onClick={() => removeImage(index)}  >
                                X
                            </span>
                        </div>

                    ))}
            </div>
        </div>
    );
}
export default AsyncUploadDocument