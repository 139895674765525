import React, { useState, useEffect } from 'react'; // Importing Modules
import "./SwitchAccount.css";
import axios from 'axios'

// Creating a function to track the changes in DropDown List
function SwitchAccount({ accountSwitchReload}) {
    //Using useState to set the defualt value of DropDown Menu and declare the values
    const [selectedValue, setSelectedValue] = useState('0');

    const [phids, setphids] = useState([]);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        console.log("===" + event.target.value);
        switchAccountCall(event.target.value);
    };

    const switchAccountCall = async (newid) => {
        const payLoad = {
            newid: newid,
            email: localStorage.getItem('uemail')
        }
        console.log("RESPONSE RECEIVED:=== ", payLoad);
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/account/switchaccount', payLoad);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    localStorage.setItem('id', newid);
                    accountSwitchReload();
                    getAccounts();
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
        }
    }


    const getAccounts = async () => {
        const payLoad = {
            email: localStorage.getItem('uemail')
        }
        console.log("sssssssssssRESPONSE RECEIVED:=== ", payLoad);
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/account/getAccounts', payLoad);
            if (res.status === 200) {
                console.log("sssssssssssRESPONSE RECEIVED:=== ", res.data);
                console.log("sssssssssssRESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    console.log("===" + res.data.data);
                    setphids([]);
                    const currentAccount = res.data.data.filter(f => f.active === true);
                    if (currentAccount.length > 0) {
                        console.log("===" + currentAccount[0].id);
                        setphids(phids => [...phids, currentAccount[0].id]);
                    }
                    const nextAccount = res.data.data.filter(f => f.active === false);
                    if (nextAccount.length > 0) {
                        console.log("===" + nextAccount[0].id);
                        setphids(phids => [...phids, nextAccount[0].id]);
                    }
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
        }
    }

    useEffect(() => {
        const account = localStorage.getItem("account");
        if (account !== undefined) {
            let accountValue = JSON.parse(account);
            console.log("===" + accountValue);
            const currentAccount = accountValue.filter(f => f.active === true);
            if (currentAccount.length > 0) {
                console.log("===" + currentAccount[0].id);
                setphids(phids => [...phids, currentAccount[0].id]);
            }
            const nextAccount = accountValue.filter(f => f.active === false);
            if (nextAccount.length > 0) {
                console.log("===" + nextAccount[0].id);
                setphids(phids => [...phids, nextAccount[0].id]);
            }
        }

    }, []);

    return (
        <div>

            <select className="switchaccount-select" value={selectedValue} onChange={handleChange}>
                {phids.map((e) => <option value={e} key={e}>{e}</option>)}
             
            </select>


        </div>
    );
}
export default SwitchAccount;