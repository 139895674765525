import React, { useState } from 'react'
import "./SecuritySettings.css";
import axios from 'axios'

const SecuritySettings = ({ profileSecuritySettingsEvent, onProfileSecuritySettingsEvent }) => {
    const [oldpassword, setOldpassword] = useState("");
    const [changepassword, setChangepassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");

    const [error, setError] = useState("");
    const [IsError, setIsError] = useState(false);
    const [oldpasswordShown, setOldPasswordShown] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [cpasswordShown, setCPasswordShown] = useState(false);
    const toggleOldPasswordVisiblity = () => {
        setOldPasswordShown(oldpasswordShown ? false : true);
    };
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleCPasswordVisiblity = () => {
        setCPasswordShown(cpasswordShown ? false : true);
    };
    const registration = async () => {
        const input = {
            "email": localStorage.getItem("uemail"),
            "password": oldpassword,
            "newpassword": changepassword
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/changepassword', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    profileSecuritySettingsEvent();
                } else {
                    setError(res.data.msg);
                    setIsError(true);
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }
    const handleSubmit = (e) => {
        if (oldpassword === '') {
            e.preventDefault();
            setError("Enter your Old Password");
            setIsError(true);
        } else if (changepassword === '') {
            e.preventDefault();
            setError("Enter Change password");
            setIsError(true);
        } else if (confirmpassword === '') {
            e.preventDefault();
            setError("Enter confirm password");
            setIsError(true);
        } else if (changepassword !== confirmpassword) {
            e.preventDefault();
            setError("password and confirm password should be match!!!");
            setIsError(true);
        } else {
            e.preventDefault();
            registration();
        }
    }

    const onscreen = (event) => {
        event.stopPropagation();
    }


    return (
        <div className="profile_background" onClick={profileSecuritySettingsEvent}>
            <div className="profilescreen" onClick={onscreen}>


               

                    <div className="profile_header">
                        <div>
                            <div className="profiletextmessage">Personal Info</div>
                            <div className="profileinfotext">Update your account details and credentails</div>
                        </div>

                    <button className='profilesecuirtysettings_button' onClick={onProfileSecuritySettingsEvent}>Profile Settings</button>
                    </div>

                <form className='profile_container' onSubmit={handleSubmit}>

                    <div className="securitysetting_password_wrapper">
                        <input type={oldpasswordShown ? "text" : "password"}
                            minLength={5}
                            name="oldpassword"
                            id='oldpassword'
                            className='securitysetting_password'
                            value={oldpassword}
                            placeholder='Enter Old Password'
                            onChange={e => setOldpassword(e.target.value)}
                        /><img className="securitysetting_password_img" alt="" onClick={toggleOldPasswordVisiblity} />
                    </div>
                    <div className="securitysetting_password_wrapper">
                        <input type={passwordShown ? "text" : "password"}
                            minLength={5}
                            name="changepassword"
                            id='changepassword'
                            className='securitysetting_password'
                            value={changepassword}
                            placeholder='Change Password'
                            onChange={e => setChangepassword(e.target.value)}
                        /><img className="securitysetting_password_img" alt="" onClick={togglePasswordVisiblity} />
                    </div>
                    <div className="securitysetting_password_wrapper">
                        <input type={cpasswordShown ? "text" : "password"}
                            minLength={5}
                            name="confirmpassword"
                            id='confirmpassword'
                            className='profile_input'
                            value={confirmpassword}
                            placeholder='Confirm Password'
                            onChange={e => setConfirmpassword(e.target.value)}
                        /><img className="securitysetting_password_img" alt="" onClick={toggleCPasswordVisiblity} />
                    </div>




                    <button className='profileupdate_button'>Update Profile</button>
                    <div hidden={!IsError}><span className="profileerrormessage">{error}</span></div>

                </form>
            </div>
        </div>
    )
}

export default SecuritySettings