import "./AccountDisconnect.css";
import axios from 'axios'
const AccountDisconnect = ({ togglePopupAccountDisconnect, selectid, removeAccount }) => {
    const accountDisconnectCall = async () => {
        const input = {
            id: selectid
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/account/accountdisconnect', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    togglePopupAccountDisconnect();
                    removeAccount();
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        accountDisconnectCall();
    }


    return (
        <>
            <div className="accountdisconnect_main">
                <div>
                    <span className="accountdisconnect_content">Are you sure want to disconnect the Account</span>
                </div>
                <div>
                    <button className='accountdisconnect_button' onClick={handleSubmit}>Yes</button>
                    <button className='accountdisconnect_button' onClick={togglePopupAccountDisconnect}>No</button>
                </div>
            </div>
        </>
    )
}
export default AccountDisconnect