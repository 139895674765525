import React, { useState } from 'react'; // Importing Modules
import "./MarkUs.css";
// Creating a function to track the changes in DropDown List
function MarkUs({ markusUnRead, markusResolved, markusBlocked, markusRoot, onMarkUsUpdate }) {
    //Using useState to set the defualt value of DropDown Menu and declare the values
    const [selectedValue, setSelectedValue] = useState('0');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onMarkUsUpdate(event.target.value);
    };
    return (
        <>
            {markusRoot === 'unread' ?
                (<select className="markus-select" value={selectedValue} onChange={handleChange}>
                    <option value="0">unread</option>
                    <option value={markusUnRead === 1 ? "read" : "unread"}>{markusUnRead === 1 ? "read" : "unread"}</option>
                    <option value={markusResolved === 1 ? "unresolved" : "resolved"}>{markusResolved === 1 ? "unresolved" : "resolved"}</option>
                    <option value={markusBlocked === 1 ? "unblocked" : "blocked"}>{markusBlocked === 1 ? "unblocked" : "blocked"}</option>
                </select>)
                : markusRoot === 'resolved' ?
                    (<select className="markus-select"  value={selectedValue} onChange={handleChange}>
                        <option value="0">resolved</option>
                        <option value={markusResolved === 1 ? "unresolved" : "resolved"}>{markusResolved === 1 ? "unresolved" : "resolved"}</option>
                        <option value={markusUnRead === 1 ? "read" : "unread"}>{markusUnRead === 1 ? "read" : "unread"}</option>
                        <option value={markusBlocked === 1 ? "unblocked" : "blocked"}>{markusBlocked === 1 ? "unblocked" : "blocked"}</option>
                    </select>)
                    : markusRoot === 'blocked' ?
                        (<select className="markus-select"  value={selectedValue} onChange={handleChange}>
                            <option value="0">blocked</option>
                            <option value={markusBlocked === 1 ? "unblocked" : "blocked"}>{markusBlocked === 1 ? "unblocked" : "blocked"}</option>
                            <option value={markusUnRead === 1 ? "read" : "unread"}>{markusUnRead === 1 ? "read" : "unread"}</option>
                            <option value={markusResolved === 1 ? "unresolved" : "resolved"}>{markusResolved === 1 ? "unresolved" : "resolved"}</option>
                            
                        </select>)
                        : (<select className="markus-select"  value={selectedValue} onChange={handleChange}>
                            <option value="0">read</option>
                            <option value={markusUnRead === 1 ? "read" : "unread"}>{markusUnRead === 1 ? "read" : "unread"}</option>
                            <option value={markusResolved === 1 ? "unresolved" : "resolved"}>{markusResolved === 1 ? "unresolved" : "resolved"}</option>
                            <option value={markusBlocked === 1 ? "unblocked" : "blocked"}>{markusBlocked === 1 ? "unblocked" : "blocked"}</option>
                        </select>)
            }
        </>
    );
}
export default MarkUs;