import React, { useState, useEffect } from 'react'
import "./Accounts.css";
import Header from "../Chats/Header";
import UpdateProfile from "../UpdateProfile/UpdateProfile";
import AccountDisconnect from "../AccountDisconnect/AccountDisconnect";
import axios from 'axios'

const Accounts = ({ socket }) => {
    const [IsMobileView, setIsMobileView] = useState(true);
    const [accesstoken, setAccesstoken] = useState("")
    const [wabaid, setWabaid] = useState("");
    const [isShowingAlertWH, setShowingAlertWH] = React.useState(false);
    const [isShowingAlertVT, setShowingAlertVT] = React.useState(false);
    const [isShowingAlertPP, setShowingAlertPP] = React.useState(false);
    const [isShowingAlertTS, setShowingAlertTS] = React.useState(false);
    const [error, setError] = useState("");
    const [IsError, setIsError] = useState(false);
    const [accountInfo, setAccountInfo] = useState([]);
    const [isOpenUpodateProfile, setIsOpenUpodateProfile] = useState(false);
    const [isOpenAccountDisconnect, setIsOpenAccountDisconnect] = useState(false);
    const [selectid, setSelectid] = useState("");
    const [selectaccesstoken, setSelectaccesstoken] = useState("");
    const [selectBusinessaddress, setBusinessaddress] = useState("");
    const [selectDescription, setDescription] = useState("");
    const [selectVertical, setVertical] = useState("");
    const [selectAbout, setAbout] = useState("");
    const [selectUemail, setUemail] = useState("");
    const [selectWebsite1, setSelectWebsite1] = useState("");
    const [selectWebsite2, setSelectWebsite2] = useState("");

    const connectAccount = async () => {
        const input = {
            "accesstoken": accesstoken,
            "wabaid": wabaid,
            "email": localStorage.getItem('uemail')
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/account/connectaccount', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    console.log("RESPONSE RECEIVED:==sadfasdfasfasfdf= ", res.data.data);
                    setAccountInfo(res.data.data);
                    setAccountInfo([res.data.data, ...accountInfo]);
                    console.log("RESPONSE RECEIVED:=== sadfasdfasdfa", accountInfo);
                } else {
                    setError(res.data.msg);
                    setIsError(true);
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }

    const togglePopupUpdateProfile = (selectid, selectaccesstoken, selectBusinessaddress, selectDescription, selectVertical, selectAbout, selectUemail, selectWebsite1, selectWebsite2) => {
        setSelectid(selectid);
        setSelectaccesstoken(selectaccesstoken);
        setBusinessaddress(selectBusinessaddress);
        setDescription(selectDescription);
        setVertical(selectVertical);
        setAbout(selectAbout);
        setUemail(selectUemail);
        setSelectWebsite1(selectWebsite1);
        setSelectWebsite2(selectWebsite2);
        setIsOpenUpodateProfile(!isOpenUpodateProfile);
    };

    const togglePopupAccountDisconnect = (selectid, selectaccesstoken, selectBusinessaddress, selectDescription, selectVertical, selectAbout, selectUemail, selectWebsite1, selectWebsite2) => {
        setSelectid(selectid);
        setSelectaccesstoken(selectaccesstoken);
        setBusinessaddress(selectBusinessaddress);
        setDescription(selectDescription);
        setVertical(selectVertical);
        setAbout(selectAbout);
        setUemail(selectUemail);
        setSelectWebsite1(selectWebsite1);
        setSelectWebsite2(selectWebsite2);
        setIsOpenAccountDisconnect(!isOpenAccountDisconnect);
    };


    const removeAccount = (e) => {
        const updatedAccount = accountInfo.filter(f => f.id !== selectid)
        setAccountInfo(updatedAccount);
    }


    const handleSubmit = (e) => {
        if (accesstoken === '') {
            e.preventDefault();
            setError("Access Token is invalid");
            setIsError(true);
        } else if (wabaid === '') {
            e.preventDefault();
            setError("WABA ID is invalid");
            setIsError(true);
        } else {
            e.preventDefault();
            connectAccount();
        }
    }

    const resize = () => {
        setIsMobileView(window.innerWidth <= 768 ? true : false);
    };

    const getData = async () => {
        try {
            const input = {
                "email": localStorage.getItem('uemail')
            }
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/account/getconnectedaccount', input)
            console.log("RESPONSE RECEIVED:=== ", res.data);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    setAccountInfo(res.data.data);
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }

    const openTapManagePayment = (e) => {
        e.preventDefault();
        window.open("https://business.facebook.com/billing_hub/accounts/details", "_blank", "noreferrer");
    }

    const openTapManageNumber = (e) => {
        e.preventDefault();
        window.open("https://business.facebook.com/latest/whatsapp_manager/overview", "_blank", "noreferrer");
    }

    const openTapManageAccount = (e) => {
        e.preventDefault();
        window.open("https://business.facebook.com/settings/whatsapp-business-accounts/325932023928419/?&tab=whatsapp_settings", "_blank", "noreferrer");
    }


    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", resize);
        setIsMobileView(window.innerWidth <= 768 ? true : false);
        return () => {
            window.removeEventListener('resize', resize);
        }

    }, []);


    const getAccountInfo = () => {
        if (accountInfo.length > 0) {
            return (
                <div className="account_setup_info_main">
                    {accountInfo.map(root => (
                        <div className="account_setup_info">
                            <div className="account_connecct_info_block">
                                <div className="account_connecct_info_block_sub">
                                    <div className="account_connecct_info_block_first">Display name</div>
                                    <div className="account_connecct_info_block_second">{root.verified_name} </div>
                                </div>
                                <div className="account_connecct_info_block_sub">
                                    <div className="account_connecct_info_block_first">Message limits</div>
                                    <div className="account_connecct_info_block_second">{root.verified_name} </div>
                                </div>
                                <div className="account_connecct_info_block_sub">
                                    <div className="account_connecct_info_block_first">Quality rating</div>
                                    <div className="account_connecct_info_block_second">{root.quality_rating} </div>
                                </div>
                                <div className="account_connecct_info_block_sub">
                                    <div className="account_connecct_info_block_first">Connected number</div>
                                    <div className="account_connecct_info_block_second">{root.display_phone_number} </div>
                                </div>
                                <div className="account_connecct_info_block_sub">
                                    <div className="account_connecct_info_block_first">Whatsapp business ac ID</div>
                                    <div className="account_connecct_info_block_second">{root.id} </div>
                                </div>
                                <div className="account_connecct_info_block_sub_last">
                                    <div className="account_connecct_info_block_first">Account status</div>
                                    <div className="account_connecct_info_block_second">{root.code_verification_status} </div>
                                </div>
                            </div>
                            <div className="account_connecct_info_block">
                                <div><button className='accountaccessinfo_refresh'>Refresh</button></div>
                                <div><button className='accountaccessinfo_updateprofile' onClick={() => togglePopupUpdateProfile(root.id, root.accesstoken, root.businessaddress, root.description, root.vertical, root.about, root.uemail, root.website1, root.website2)} >Update Profile</button></div>
                                <div><button className='accountaccessinfo_registeraccount'>Register Account</button></div>
                                <div><button className='accountaccessinfo_managepayment' onClick={openTapManagePayment}>Manage Payment</button></div>
                                <div><button className='accountaccessinfo_manageaccount' onClick={openTapManageAccount }>Manage Account</button></div>
                                <div><button className='accountaccessinfo_managenumber' onClick={openTapManageNumber}>Manage Number</button></div>
                                <div><button className='accountaccessinfo_disconnect' onClick={() => togglePopupAccountDisconnect(root.id, root.accesstoken, root.businessaddress, root.description, root.vertical, root.about, root.uemail, root.website1, root.website2)} >Disconnect</button></div>
                            </div>
                        </div>
                    ))}
                </div>
            );

        } else {
            return (
                <>

                </>
            );
        }
    };


    return (

        <>
            <div className="account_header">
                <Header IsMobileView={IsMobileView} IsCharbarView={true} />
            </div>

            <div className="account_main">

                <div className="account_main_menu">
                </div>

                <div className="account_main_body">
                    <div className="account_setup">
                        <div><span className="accountsetupmessage">Setup Whatsapp Account</span></div>
                        <div><span className="accountsetupmessageinfo">Setup your integration to be able to receive and send messages via Whatsapp.</span></div>

                        <div className="accountsetupmessagewebhookdiv">
                            <div>
                                <div>
                                    <span className="accountsetupmessagewebhook">Webhook url : https://bulkwise.in/whatsapp-webhook</span>
                                    <img className="accountsetupmessage_img" alt="" onClick={() => { navigator.clipboard.writeText('https://bulkwise.in/whatsapp-webhook'); setShowingAlertWH(true); }} />
                                    <span className={isShowingAlertWH ? "accountsetupmessage_img_message_shown" : "accountsetupmessage_img_message_hidden"}
                                        onTransitionEnd={() => setShowingAlertWH(false)}> copied!!!</span>
                                </div>
                                <div>
                                    <span className="accountsetupmessagewebhook">Verification Token : 1234567890</span>
                                    <img className="accountsetupmessage_img" alt="" onClick={() => { navigator.clipboard.writeText('1234567890'); setShowingAlertVT(true); }} />
                                    <span className={isShowingAlertVT ? "accountsetupmessage_img_message_shown" : "accountsetupmessage_img_message_hidden"}
                                        onTransitionEnd={() => setShowingAlertVT(false)}> copied!!!</span>
                                </div>
                            </div>
                            <div className="accountsetupmessagewebhookdivright">
                                <div>
                                    <span className="accountsetupmessagewebhook">Privacy Policy url: https://bulkwise/privacy</span>
                                    <img className="accountsetupmessage_img" alt="" onClick={() => { navigator.clipboard.writeText('https://bulkwise/privacy'); setShowingAlertPP(true); }} />
                                    <span className={isShowingAlertPP ? "accountsetupmessage_img_message_shown" : "accountsetupmessage_img_message_hidden"}
                                        onTransitionEnd={() => setShowingAlertPP(false)}> copied!!!</span>
                                </div>
                                <div>
                                    <span className="accountsetupmessagewebhook">Terms & Service url: https://bulkwise/terms</span>
                                    <img className="accountsetupmessage_img" alt="" onClick={() => { navigator.clipboard.writeText('https://bulkwise/terms'); setShowingAlertTS(true); }} />
                                    <span className={isShowingAlertTS ? "accountsetupmessage_img_message_shown" : "accountsetupmessage_img_message_hidden"}
                                        onTransitionEnd={() => setShowingAlertTS(false)}> copied!!!</span>
                                </div>
                            </div>
                        </div>
                        <div className="accountsetupmessagewebhookdiv">
                            <div>
                                <input type="text"
                                    minLength={5}
                                    name="accesstoken"
                                    id='accesstoken'
                                    className='accountaccesstoken_input'
                                    value={accesstoken}
                                    placeholder='Enter your Access Token'
                                    onChange={e => setAccesstoken(e.target.value)}
                                />
                            </div>
                            <div>
                                <input type="text"
                                    minLength={5}
                                    name="wabaid"
                                    id='wabaid'
                                    className='accountaccesstoken_input'
                                    value={wabaid}
                                    placeholder='Enter your WABA ID'
                                    onChange={e => setWabaid(e.target.value)}
                                />
                            </div>
                            <div><button className='accountaccesstoken_button' onClick={handleSubmit}>Connect Account</button></div>
                        </div>
                        <div hidden={!IsError} className="accountsetuperrormessage"><span>{error}</span></div>
                    </div>
                    {getAccountInfo()}
                </div>
            </div >
            {isOpenUpodateProfile && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <UpdateProfile togglePopupUpdateProfile={togglePopupUpdateProfile} selectid={selectid} selectaccesstoken={selectaccesstoken} selectBusinessaddress={selectBusinessaddress} selectDescription={selectDescription} selectVertical={selectVertical} selectAbout={selectAbout} selectUemail={selectUemail} selectWebsite1={selectWebsite1} selectWebsite2 = {selectWebsite2}/>
                    </div>
                </div>
            )}

            {isOpenAccountDisconnect && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <AccountDisconnect togglePopupAccountDisconnect={togglePopupAccountDisconnect} selectid={selectid}  removeAccount={removeAccount} />
                    </div>
                </div>
            )}

        </>
    )
}

export default Accounts