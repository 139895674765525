import React, { useEffect, useState, useRef } from "react";
import "../..//menu.css";
import "./Chats.css";
import ChatBar from "./ChatBar";
import ChatMessage from "./ChatMessage";
import UserInfo from "./UserInfo";
import SideMenu from "./SideMenu";
import Header from "./Header";
import axios from 'axios'

function Chats({ socket }) {

    const messageTextRef = useRef(null);
    const lastMessageRef = useRef(null);
    const [replymessage, setReplymessage] = useState({});
    const [selectedchatbarmenu, setSelectedchatbarmenu] = useState("")
    const [IsMobileView, setIsMobileView] = useState(true);
    const [IsCharbarView, setIsCharbarView] = useState(false);
    const [IsMessageView, setIsMessageView] = useState(false);
    const [IsUserinfoView, setIsUserinfoView] = useState(false);
    const chatbarfiltermenuItems = ["All", "Unread", "resolved", "blocked"];
    const resize = () => {
        setIsMobileView(window.innerWidth <= 768 ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", resize);
        setIsMobileView(window.innerWidth <= 768);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);
    const [roots, setRoots] = useState([])
    const [messages, setMessages] = useState([])
    


    const getData = async () => {
        const payload = {
            phid: localStorage.getItem('id')
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/conversation', payload);
            if (res.status === 200) {
                console.log("getData   "+localStorage.getItem('id') + "    RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    setRoots(res.data.data);
                } 
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
        }
        socket.emit("register",
            {
                phid: localStorage.getItem("id")
            }
        )
    }

    const accountSwitchReload = () => {
        localStorage.removeItem("selectedNumber");
        localStorage.removeItem("selectedName");
        setSelectedchatbarmenu(chatbarfiltermenuItems[0]);
        visibleMobilePage(1);
        setMessages([]);
        getData();
    }

    useEffect(() => {
        localStorage.removeItem("selectedNumber");
        localStorage.removeItem("selectedName");
        setSelectedchatbarmenu(chatbarfiltermenuItems[0]);
        visibleMobilePage(1);
        setMessages([]);
        getData();
    }, []);

    const [selectedNumber, setSelectedNumber] = useState("")
    useEffect(() => {
        const eventListener = (data) => {
            const exist = roots.filter(f => f.number === data.number)
            if (exist.length === 0) {
                const item = {
                    number: data.number,
                    name: data.name,
                    visible: data.visible,
                    unread: data.unread,
                    resolved: data.resolved,
                    blocked: data.blocked,
                    active: data.active,
                    converstation: [{
                        id: data.converstation.id,
                        messageid: data.converstation.messageid,
                        message: data.converstation.message,
                        contextfrom: data.converstation.contextfrom,
                        contextid: data.converstation.contextid,
                        type: data.converstation.type,
                        source: data.converstation.source,
                        status: data.converstation.status,
                        caption: data.converstation.caption,
                        createdat: data.converstation.createdat,
                        updatedat: data.converstation.updatedat
                    }]
                };
                setRoots([item, ...roots]);
            } else {
                var count = 0;
                var index = 0;
                var value;
                const updatedRootMessages = roots.map(root => {
                    count = count + 1;
                    if (root.number === data.number) {
                        root.visible = data.visible;
                        root.unread = data.unread;
                        root.resolved = data.resolved;
                        root.blocked = data.blocked;
                        root.active = data.active;
                        index = count;
                        const converstation = {
                            id: data.converstation.id,
                            messageid: data.converstation.messageid,
                            message: data.converstation.message,
                            contextfrom: data.converstation.contextfrom,
                            contextid: data.converstation.contextid,
                            type: data.converstation.type,
                            source: data.converstation.source,
                            status: data.converstation.status,
                            caption: data.converstation.caption,
                            createdat: data.converstation.createdat,
                            updatedat: data.converstation.updatedat
                        };
                        root.converstation.push(converstation);
                        if (selectedNumber === data.number) {
                            setMessages(messages => [...messages, converstation]);
                        }
                        value = root;
                        return root;
                    }
                    return root;
                });
                if (index > 1) {
                    updatedRootMessages.splice((index - 1), 1)
                    updatedRootMessages.unshift(value);
                }
                setRoots(updatedRootMessages);
            }
        };
        socket.on("message", eventListener);
        return () => socket.off("message", eventListener)
    }, [socket, roots, messages, selectedNumber])

    useEffect(() => {
        const eventListener = (data) => {
            const updatedRootMessages = roots.map(root => {
                if (root.number === data.number) {
                    const updatedConverstations = root.converstation.map(converstation => {
                        if (converstation.id === data.converstation.id) {
                            converstation.status = data.converstation.status;
                            converstation.createdat = data.converstation.createdat;
                            return converstation;
                        }
                        return converstation;
                    });
                    root.converstation = updatedConverstations;
                    return root;
                }
                return root;
            });
            setRoots(updatedRootMessages)
        };
        socket.on("update", eventListener);
        return () => socket.off("update", eventListener)
    }, [socket, roots])

    const [markusRoot, setmarkusRoot] = useState("")
    const [markusUnRead, setmarkusUnRead] = useState("")
    const [markusResolved, setmarkusResolved] = useState("")
    const [markusBlocked, setmarkusBlocked] = useState("")

    const onMarkUsUpdate = async (value) => {
        console.log("===",value)
        if (value === 'unresolved') {
            const updatedRootMessages = roots.map(root => {
                if (root.number === localStorage.getItem("selectedNumber")) {
                    if (root.resolved > 0) {
                        root.resolved = 0;
                    }
                }
                return root;
            });
            socket.emit("resolvedupdate",
                {
                    number: localStorage.getItem("selectedNumber"),
                    value: 0
                }
            )
            setRoots(updatedRootMessages);
        } else if (value === 'resolved') {
            const updatedRootMessages = roots.map(root => {
                if (root.number === localStorage.getItem("selectedNumber")) {
                    if (root.resolved === 0) {
                        root.resolved = 1;
                    }
                }
                return root;
            });
            socket.emit("resolvedupdate",
                {
                    number: localStorage.getItem("selectedNumber"),
                    value: 1
                }
            )
            setRoots(updatedRootMessages);
        } else if (value === 'unblocked') {
            const updatedRootMessages = roots.map(root => {
                if (root.number === localStorage.getItem("selectedNumber")) {
                    if (root.blocked > 0) {
                        root.blocked = 0;
                    }
                }
                return root;
            });
            socket.emit("blockedupdate",
                {
                    number: localStorage.getItem("selectedNumber"),
                    value: 0
                }
            )
            setRoots(updatedRootMessages);
        } else if (value === 'blocked') {
            const updatedRootMessages = roots.map(root => {
                if (root.number === localStorage.getItem("selectedNumber")) {
                    if (root.blocked === 0) {
                        root.blocked = 1;
                    }
                }
                return root;
            });
            socket.emit("blockedupdate",
                {
                    number: localStorage.getItem("selectedNumber"),
                    value: 1
                }
            )
            setRoots(updatedRootMessages);
        } else if (value === 'read') {
            const updatedRootMessages = roots.map(root => {
                if (root.number === localStorage.getItem("selectedNumber")) {
                    if (root.unread > 0) {
                        root.unread = 0;
                    }
                }
                return root;
            });
            socket.emit("readupdate",
                {
                    number: localStorage.getItem("selectedNumber"),
                    value: 0
                }
            )
            setRoots(updatedRootMessages);
        } else if (value === 'unread') {
            const updatedRootMessages = roots.map(root => {
                if (root.number === localStorage.getItem("selectedNumber")) {
                    if (root.unread === 0) {
                        root.unread = 1;
                    }
                }
                return root;
            });
            socket.emit("readupdate",
                {
                    number: localStorage.getItem("selectedNumber"),
                    value: 1
                }
            )
            setRoots(updatedRootMessages);
        }
    }

    const onCharBarUpdate = (event) => {
        //setIsChatOpen(true);
        visibleMobilePage(2)
        setSelectedNumber(event);
        messageTextRef.current.value = "";
        localStorage.setItem("selectedNumber", event);
        socket.emit("readupdate",
            {
                number: event,
                value: 0
            }
        )
        var result = [];
        var timevalue = "";

        const updatedRootMessages = roots.map(root => {
            if (root.number === event) {
                //setSelectedName(root.name);
                localStorage.setItem("selectedName", root.name);
                setmarkusUnRead(root.unread);
                setmarkusResolved(root.resolved);
                setmarkusBlocked(root.blocked);
                timevalue = Object.assign("", root.active);
                localStorage.setItem("active", root.active);
                result = Object.assign([], root.converstation);
                root.unread = 0;
            }
            return root;
        });
        setRoots(updatedRootMessages);
        setMessages(result);
        handlereplyclose();
        document.getElementById("chatbarfooder_textareaid").focus();
        //setTimer(timevalue);


        /* roots.forEach((root) => {
             if (root.number === event) {
                 setSelectedName(root.name);
                 localStorage.setItem("selectedName", root.name);
                 result = Object.assign([], root.converstation);
             }
         });*/

    };

    const onSearchBar = (event) => {
        event.preventDefault();
        const updatedRootMessages = roots.map(root => {
            if (!((root.number.includes(event.target.value)
                || root.name.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())))) {
                root.visible = false;
            } else {
                root.visible = true;
            }
            return root;
        });

        setRoots(updatedRootMessages);
    };

    const handlereply = (messageid) => {
        if (messageid !== null) {
            let message = messages.filter(message => message.messageid === messageid);
            if (message.length > 0) {
                setReplymessage(message[0]);
            }
            document.getElementById("chatbarfooder_textareaid").focus();
        }
    }

    const handlereplyclose = () => {
        setReplymessage({});
    }

    const onPageUpdate = (event) => {
        setSelectedchatbarmenu(event)
        //searchTextRef.current.value = "";
        messageTextRef.current.value = "";
        if (event === 'Unread') {
            setmarkusRoot('Unread');
            const updatedRootMessages = roots.map(root => {
                if (root.unread > 0) {
                    root.visible = true;
                } else {
                    root.visible = false;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        } else if (event === 'resolved') {
            setmarkusRoot('resolved');
            const updatedRootMessages = roots.map(root => {
                if (root.resolved > 0) {
                    root.visible = true;
                } else {
                    root.visible = false;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        } else if (event === 'blocked') {
            setmarkusRoot('blocked');
            const updatedRootMessages = roots.map(root => {
                if (root.blocked > 0) {
                    root.visible = true;
                } else {
                    root.visible = false;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        } else if (event === 'All') {
            setmarkusRoot('All');
            const updatedRootMessages = roots.map(root => {
                if (root.blocked || root.resolved > 0) {
                    root.visible = false;
                } else {
                    root.visible = true;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        }
        //setMessages([]);
        //localStorage.removeItem("selectedName")
        //localStorage.removeItem("selectedNumber")
    }

    const onBackButton = (value) => {
        visibleMobilePage(value);
    }
    const userinfochat = () => {
        visibleMobilePage(3);

    }


    const visibleMobilePage = (value) => {
        if (value === 2) {
            setIsCharbarView(false);
            setIsMessageView(true);
            setIsUserinfoView(false);
        } else if (value === 3) {
            setIsCharbarView(false);
            setIsMessageView(false);
            setIsUserinfoView(true);
        } else {
            setIsCharbarView(true);
            setIsMessageView(false);
            setIsUserinfoView(false);
        }


    }

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [roots]);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (

        <>


            <div className="chats_header" hidden={(IsMobileView && IsMessageView) || (IsMobileView && IsUserinfoView)} >
                <Header IsMobileView={IsMobileView} onBackButton={onBackButton} IsUserinfoView={IsUserinfoView} IsMessageView={IsMessageView} IsCharbarView={IsCharbarView} userinfochat={userinfochat} />
            </div>
            <div className="chats_main">

                <div className="chats_main">
                    <div hidden={IsMobileView} className="chats_main_menu">
                       {/* <SideMenu />*/}
                    </div>
                    <div hidden={IsMobileView && !IsCharbarView} className="chats_main_charbar">
                        <ChatBar onPageUpdate={onPageUpdate} selectedchatbarmenu={selectedchatbarmenu} onCharBarUpdate={onCharBarUpdate} chatbarfiltermenuItems={chatbarfiltermenuItems} onSearchBar={onSearchBar} roots={roots} selectedNumber={selectedNumber} accountSwitchReload={accountSwitchReload} />
                    </div>
                    <div hidden={IsMobileView && !IsMessageView} className="chats_main_message">
                        <ChatMessage IsMobileView={IsMobileView} lastMessageRef={lastMessageRef} socket={socket} messageTextRef={messageTextRef} handlereplyclose={handlereplyclose} handlereply={handlereply} replymessage={replymessage} messages={messages} markusUnRead={markusUnRead} markusResolved={markusResolved} markusBlocked={markusBlocked} markusRoot={markusRoot} onMarkUsUpdate={onMarkUsUpdate} userinfochat={userinfochat} onBackButton={onBackButton} />
                    </div>
                    <div hidden={IsMobileView && !IsUserinfoView} className="chats_main_temp">
                        <UserInfo replymessage={replymessage} messages={messages} IsMobileView={IsMobileView} onBackButton={onBackButton} />
                    </div>
                </div>
            </div>
        </>

    )
}

export default Chats;
