import React from 'react';
import "./ChatMessage.css"
import { format } from 'date-fns';
import MarkUs from './MarkUs';
import ChatFooter from './ChatFooter'

import AsyncImage from './AsyncImage'
import PlayVideo from './PlayVideo'
import PlayAudio from './PlayAudio'
import AsyncDocument from './AsyncDocument'

const bcolor = ['#D4FFC5', '#E8D1FF', '#FFC5CC', '#FEFFC5', '#FFC5F6', '#C5F1FF', '#C5FFEA', '#F0FFC5', '#FFEBC5', '#C5E3FF', '#FFEDC5', '#FFD7C5', '#CDC5FF', '#FFC5D3', '#F7FFC5', '#FFDAC5', '#FFF2C5', '#FFC5E8'];
const tcolor = ['#4BAC29', '#8239CC', '#CC5462', '#BBBE2B', '#C663B7', '#42A3C2', '#5DAD90', '#98BB35', '#E6A323', '#318CE0', '#E5DC18', '#E0581B', '#280BDA', '#DC2652', '#C1DB20', '#EF671A', '#DBDF1E', '#D8238F'];


const ChatMessage = ({ IsMobileView, lastMessageRef, socket, messageTextRef, handlereplyclose, replymessage, handlereply, timer, unread, onUnReadUpdate, resolved, onResolvedUpdate, blocked, onBlockUpdate, messages, typingStatus, markusUnRead, markusResolved, markusBlocked, markusRoot, onMarkUsUpdate, userinfochat, onBackButton }) => {

    const NOW_IN_MS = new Date().getTime();
    const nNOW_IN_MS = new Date(localStorage.getItem("active")).getTime();

    const value = (NOW_IN_MS - nNOW_IN_MS) < 86400000 ? (NOW_IN_MS - nNOW_IN_MS) : 0;

    const dateTimeAfterThreeDays = (value === 0 ? 0 : (NOW_IN_MS + (86400000 - value)));

    const getBcolor = (number) => {
        if (number.length !== 12)
            return bcolor[0];
        else
            return bcolor[parseInt(number[10]) + parseInt(number[11])];
    }

    const getTcolor = (number) => {
        if (number.length !== 12)
            return tcolor[0];
        else
            return tcolor[parseInt(number[10]) + parseInt(number[11])];
    }

    const getName = (name) => {
        return name.split(' ').filter((n, idx) => idx < 2).map((n) => n[0]).join('').toUpperCase()
    }

    const formatDate = (date) => {
        var today = new Date(),
            input = new Date(date),
            diff_days = today.getDate() - input.getDate(),
            diff_month = today.getMonth() - input.getMonth(),
            diff_year = today.getFullYear() - input.getFullYear();

        if (diff_days === 0 && diff_month === 0 && diff_year === 0) {
            return format(new Date(date), "hh:mm a")
        } else {
            return format(new Date(date), "dd/MM/yyyy hh:mm a")
        }
    };

    const getMessageByContextId = (contextid) => {
        if (contextid !== null) {
            let message = messages.filter(message => message.messageid === contextid);
            if (message.length > 0) {
                return message[0];// setCmessage(message[0]);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const formatText = (text) => {
        var val = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
        return val.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }

    const handlefocus = (contextid) => {
        let element = document.getElementById(contextid);
        if (element !== null) {
            element.focus();
        }
    }


    const getcontextText = (contextid) => {
        let message = getMessageByContextId(contextid);
        if (message === null) {
            return (<></>);
        } else {
            return (<div className="chatmessage_contextcontainer" onClick={() => handlefocus(message.messageid)}>
                {message.source === 'client' ?
                    <div className="chatmessage_contextcontainer_inside">
                        <span className="chatmessage_contextsource">you</span> </div> :
                    <div className="chatmessage_contextcontainer_inside">
                        <span className="chatmessage_contextsource">whatsapp</span></div>}
                {message.type === 'text'
                    ?
                    <div className="chatmessage_contextcontainer_inside">
                        <span className="chatmessage_contextmessager" dangerouslySetInnerHTML={{ __html: formatText(message.message) }}></span>
                    </div>
                    : (message.type === 'image'
                        ? <div className="chatmessage_contextcontainer_inside_img">
                            <img src={require('../../../Images/chatlist-image.png')} alt="images" onClick={() => handlefocus(message.messageid)} />
                        </div>
                        :
                        (message.type === 'video' ?
                            <div className="chatmessage_contextcontainer_inside_img">
                                <img src={require('../../../Images/chatlist-video.png')} alt="video" onClick={() => handlefocus(message.messageid)} />
                            </div>
                            :
                            (message.type === 'audio' ?
                                <div className="chatmessage_contextcontainer_inside_img">
                                    <img src={require('../../../Images/chatlist-audio.png')} alt="audio" onClick={() => handlefocus(message.messageid)} />
                                </div>
                                :
                                (message.type === 'document' ?
                                    <div className="chatmessage_contextcontainer_inside_img">
                                        <img src={require('../../../Images/chatlist-doc.png')} alt="document" onClick={() => handlefocus(message.messageid)} />
                                    </div>
                                    : <div className="chatmessage_contextcontainer_inside">
                                        <span className="chatmessage_contextmessager" dangerouslySetInnerHTML={{ __html: formatText(message.message) }} onClick={() => handlefocus(message.messageid)}></span>
                                    </div>
                                )))
                    )}


            </div>)
        }
    }

    return (
        <>

            <div className="chatmessagemainheader">
                {localStorage.getItem("selectedNumber") !== null ?
                    (<div className="chatmessage_user_avatar">
                        {IsMobileView && <img className="chatmessage_user_backimage" alt="" onClick={() => onBackButton(1)} />}
                        <div className="chatmessage_user_avatar_outerroot">
                            <div className="chatmessage_user_avatar_outer">
                                <div style={{ backgroundColor: getBcolor(localStorage.getItem("selectedNumber")), color: getTcolor(localStorage.getItem("selectedNumber")) }}
                                    className="chatmessage_user_avatar_inner">  {getName(localStorage.getItem("selectedName"))}
                                </div>
                            </div>
                        </div>
                        <div className="chatmessage_user_namenumber">
                            <div className="chatmessage_user_namenumber_name">{localStorage.getItem("selectedName")}</div>
                            <div className="chatmessage_user_namenumber_name">{localStorage.getItem("selectedNumber")}</div>
                        </div>

                        {IsMobileView && 
                        <div>
                                <img className="chatmessage_user_infoimage" alt="" onClick={userinfochat} />
                        <MarkUs  markusUnRead={markusUnRead} markusResolved={markusResolved} markusBlocked={markusBlocked} markusRoot={markusRoot} onMarkUsUpdate={onMarkUsUpdate} />
                            </div>}
                        {!IsMobileView &&
                            <MarkUs markusUnRead={markusUnRead} markusResolved={markusResolved} markusBlocked={markusBlocked} markusRoot={markusRoot} onMarkUsUpdate={onMarkUsUpdate} />
                        }

                        {/*{IsMobileView && (<div className="chatbody__temp_header_time_outer">
                            <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                        </div>)}*/}
                        {/*{unread === 1 ? <div><button className="tab_button" onClick={onUnReadUpdate}>un-unread</button></div> : <div><button className="tab_button" onClick={onUnReadUpdate}>read</button></div>}
                            {resolved === 1 ? <div><button className="tab_button" onClick={onResolvedUpdate}>un-resolved</button></div> : <div ><button className="tab_button" onClick={onResolvedUpdate}>resolved</button></div>}
                            {blocked === 1 ? <div><button className="tab_button" onClick={onBlockUpdate}>un-blocked</button></div> : <div><button className="tab_button" onClick={onBlockUpdate}>blocked</button></div>}
                          */}  {/*<CountdownTimer targetDate={dateTimeAfterThreeDays} />*/}
                    </div>)
                    : <div> {/*className="chatbodyconverstion" *//*<p> Conversations</p>*/}</div>
                }
                {/* <button className='leaveChat__btn' >Sign out</button>*/}

            </div>
            <div className="chatmessagemain" >
                {messages.map(message => (message.source === 'client') ?
                    <div className="chatmessage_messagecontainermain" key={message.id} >
                        <div className="chatmessage_messagecontainerright">
                            <div className="chatmessage_messagecontainerrightwithreply">
                                <div className="replyimagemessage"><img src={require('../../../Images/reply-left.png')} alt="test" onClick={() => handlereply(message.messageid)} /></div>
                                <div className="chatmessage_messagecontainerrightinner" id={message.messageid} tabIndex={-1}>
                                    <div className="chatmessage_messagecontainer_inside">
                                        {(message.contextid !== null && <div> {getcontextText(message.contextid)}</div>)}


                                        {message.type === 'text' ? <span className="chatmessage_messagemessage">{message.message}</span>
                                            : (message.type === 'image' ? <AsyncImage imgId={message.message} caption={message.caption}> </AsyncImage>
                                                : (message.type === 'video' ? <PlayVideo sourceId={message.message} caption={message.caption}> </PlayVideo>
                                                    : (message.type === 'audio' ? <PlayAudio sourceId={message.message} caption={message.caption}> </PlayAudio>
                                                        : (message.type === 'document' ? <AsyncDocument sourceId={message.message} caption={message.caption}> </AsyncDocument>
                                                            : <span className="chatmessage_messagemessage">{message.message}</span>)))
                                            )}


                                        <div className="chatmessage_timedetailscontainer">
                                            <span className="chatmessage_timedetails">{message.createdat !== undefined ? formatDate(message.createdat) : ''}</span>
                                            {message.status === null
                                                ? <div />
                                                : (message.status === 'read'
                                                    ? <img className="img" alt="" src={require('../../../Images/chatlist-bluetick.png')} />
                                                    : ((message.status === 'sent' || message.status === 'init')
                                                        ? <img className="img" alt="" src={require('../../../Images/chatlist-singletick.png')} />
                                                        : <img className="img" alt="" src={require('../../../Images/chatlist-doubletick.png')} />)
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="chatmessage_messagecontainermain">
                        <div className="chatmessage_messagecontainerleft">
                            <div className="chatmessage_messagecontainerleftwithreply">
                                <div className="chatmessage_messagecontainerleftinner" id={message.messageid} tabIndex={-1}>
                                    <div className="chatmessage_messagecontainer_inside">
                                        {(message.contextid !== null && <div> {getcontextText(message.contextid)}</div>)}
                                        {message.type === 'text' ? <span className="chatmessage_messagemessage">{message.message}</span>
                                            : (message.type === 'image' ? <AsyncImage imgId={message.message} caption={message.caption}> </AsyncImage>
                                                : (message.type === 'video' ? <PlayVideo sourceId={message.message} caption={message.caption}> </PlayVideo>
                                                    : (message.type === 'audio' ? <PlayAudio sourceId={message.message} caption={message.caption}> </PlayAudio>
                                                        : (message.type === 'document' ? <AsyncDocument sourceId={message.message} caption={message.caption}> </AsyncDocument>
                                                            : <span className="chatmessage_messagemessage">{message.message}</span>)))
                                            )}
                                        <div className="chatmessage_timedetailscontainer">
                                            <span className="chatmessage_timedetails">{message.createdat !== undefined ? formatDate(message.createdat) : ''}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="replyimagemessage"><img src={require('../../../Images/reply-right.png')} alt="test" onClick={() => handlereply(message.messageid)} /></div>
                            </div>
                        </div>
                    </div>)}
                <div ref={lastMessageRef} />
            </div>
            <div className="chatmessagemainfeader">
                <ChatFooter socket={socket} messageTextRef={messageTextRef} handlereplyclose={handlereplyclose} replymessage={replymessage} />
            </div>
        </>
    )
};

export default ChatMessage;