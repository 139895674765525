// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdowntimer {
    text-align: center;
    display: flex;
   
}

    .countdowntimer > div {
        width: 6vh;
        height: 6vh;
        margin:2vh;
        box-sizing: initial;
        background: #fff;
        color: #666;
        text-align: center;
        border-radius: 5%;
        line-height: 6vh;
        box-sizing: content-box;
    }
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/CountdownTimer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;;AAEjB;;IAEI;QACI,UAAU;QACV,WAAW;QACX,UAAU;QACV,mBAAmB;QACnB,gBAAgB;QAChB,WAAW;QACX,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;QAChB,uBAAuB;IAC3B","sourcesContent":[".countdowntimer {\r\n    text-align: center;\r\n    display: flex;\r\n   \r\n}\r\n\r\n    .countdowntimer > div {\r\n        width: 6vh;\r\n        height: 6vh;\r\n        margin:2vh;\r\n        box-sizing: initial;\r\n        background: #fff;\r\n        color: #666;\r\n        text-align: center;\r\n        border-radius: 5%;\r\n        line-height: 6vh;\r\n        box-sizing: content-box;\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
