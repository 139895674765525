import React, { useState } from "react";
import axios from 'axios'
import "./AsyncUploadVideo.css";
const AsyncUploadVideo = ({ togglePopupVideo, socket }) => {
    var singleFileObj = [];
    var singleFileArray = [];
    const [singleFileObjValue, setSingleFileObjValue] = useState([]);
    const [singleFileArrayValue, setSingleFileArrayValue] = useState([]);
    const onVideoChange = event => {
        for (let i = 0; i < event.target.files.length; i++) {
            singleFileObj.push(event.target.files[i]);
            singleFileArray.push(URL.createObjectURL(event.target.files[i]));
        }
        setSingleFileObjValue(singleFileObj);
        setSingleFileArrayValue(singleFileArray);
    };
    const shoot = async () => {
        for (let i = 0; i < singleFileObjValue.length; i++) {
            let formData = new FormData();
            formData.append('file', singleFileObjValue[i]);
            formData.append('messaging_product', 'whatsapp');
            try {
                let res = await axios.post('https://graph.facebook.com/v20.0/' + localStorage.getItem("id") + '/media', formData,
                    {
                        headers: {

                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem("accesstoken")
                        },
                    }
                );
                if (res.status === 200) {
                    socket.emit("media",
                        {
                            to: localStorage.getItem("selectedNumber"),
                            name: localStorage.getItem("selectedName"),
                            id: res.data.id,
                            phid: localStorage.getItem("id"),
                            type: 'video',
                            caption: ''
                        }
                    )
                }
            } catch (error) {
                console.error('Error uploading img : ', error);
            }
        }
        togglePopupVideo();
    }
    const removeImage = (index) => {
        setSingleFileArrayValue([
            ...singleFileArrayValue.slice(0, index),
            ...singleFileArrayValue.slice(index + 1, singleFileArrayValue.length)
        ]);

        setSingleFileObjValue([
            ...singleFileObjValue.slice(0, index),
            ...singleFileObjValue.slice(index + 1, singleFileObjValue.length)
        ]);
    };

    return (
        <div>
            <div className="asyncuploadvideoscreenheader">
                <p>Select Video</p>
                <span onClick={togglePopupVideo}>X</span>
            </div>
            <div className="asyncuploadvideoscreensend">
                <input type="file" name="myImage" multiple accept="video/mp4" onChange={onVideoChange} />
                <button onClick={shoot}>Send</button>
            </div>
            <div className="asyncuploadvideoscreen">
                {singleFileArrayValue.length !== 0 &&
                    singleFileArrayValue.map((url, index) => (
                        <div key={index} >
                            <div className="asyncuploadvideo_img_background">
                                <video controls className="asyncuploadvideo_img_content" src={url} alt="..." key={index} />
                            </div>
                            <span className="asyncuploadvideo_remove_img" onClick={() => removeImage(index)}  >
                                X
                            </span>
                        </div>

                    ))}
            </div>
        </div>
    );
}
export default AsyncUploadVideo