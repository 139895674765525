import React, { useState, useEffect } from 'react'
import { validate as validateEmail } from 'email-validator';
import "./Profile.css";
import axios from 'axios'

const Profile = ({ profileEvent, onProfileEvent }) => {
    const [businessName, setBusinessName] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [timezone, setTimezone] = useState("");
    const [error, setError] = useState("");
    const [IsError, setIsError] = useState(false);
    const registration = async () => {
        const input = {
            "name": name,
            "businessname": businessName,
            "number": mobile,
            "email": email,
            "address": address,
            "city": city,
            "state": state,
            "country": country,
            "timezone": timezone,
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/personalInfoUpdate', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    localStorage.setItem('uname', res.data.uname);
                    localStorage.setItem('ubusinessname', res.data.ubusinessname);
                    localStorage.setItem('uph_number', res.data.uph_number);
                    localStorage.setItem('uemail', res.data.uemail);
                    localStorage.setItem('uaddress', res.data.address);
                    localStorage.setItem('ucity', res.data.city);
                    localStorage.setItem('ustate', res.data.state);
                    localStorage.setItem('ucountry', res.data.country);
                    localStorage.setItem('utimezone', res.data.timezone);
                    profileEvent();
                } else {
                    setError(res.data.msg);
                    setIsError(true);
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }
    const handleSubmit = (e) => {
        if (businessName === '') {
            e.preventDefault();
            setError("Enter your businessName");
            setIsError(true);
        } else if (name === '') {
            e.preventDefault();
            setError("Enter your name");
            setIsError(true);
        } else if (mobile === '') {
            e.preventDefault();
            setError("Enter your Mobile Number");
            setIsError(true);
        } else if (!(mobile.match('[0-9]{12}'))) {
            e.preventDefault();
            setError("Invalid Mobile Number");
            setIsError(true);
        } else if (email === '') {
            e.preventDefault();
            setError("Enter your Email");
            setIsError(true);
        } else if (validateEmail(email) === false) {
            e.preventDefault();
            setError("Invalid  emailId");
            setIsError(true);
        } else if (address === '') {
            e.preventDefault();
            setError("Enter your Address");
            setIsError(true);
        } else if (city === '') {
            e.preventDefault();
            setError("Enter your City");
            setIsError(true);
        } else if (state === '') {
            e.preventDefault();
            setError("Enter your State");
            setIsError(true);
        } else if (country === '') {
            e.preventDefault();
            setError("Enter your country");
            setIsError(true);
        } else if (timezone === '') {
            e.preventDefault();
            setError("Enter your timezone");
            setIsError(true);
        } else {
            e.preventDefault();
            registration();
        }
    }

    const onscreen = (event) => {
        event.stopPropagation();
    }



    useEffect(() => {
        setName(localStorage.getItem('uname'));
        setBusinessName(localStorage.getItem('ubusinessname'));
        setMobile(localStorage.getItem('uph_number'));
        setEmail(localStorage.getItem('uemail'));
        if (localStorage.getItem('uaddress') !== 'null') {
            setAddress(localStorage.getItem('uaddress'));
        }
        if (localStorage.getItem('ucity') !== 'null') {
            setCity(localStorage.getItem('ucity'));
        }
        if (localStorage.getItem('ustate') !== 'null') {
            setState(localStorage.getItem('ustate'));
        }
        if (localStorage.getItem('ucountry') !== 'null') {
            setCountry(localStorage.getItem('ucountry'));
        }
        if (localStorage.getItem('utimezone') !== 'null') {
            setTimezone(localStorage.getItem('utimezone'));
        }
       


    }, []);
    return (
        <div className="profile_background" onClick={profileEvent}>
            <div className="profilescreen" onClick={onscreen}>
                <div className="profile_header">
                    <div>
                        <div className="profiletextmessage">Personal Info</div>
                        <div className="profileinfotext">Update your account details and credentails</div>
                    </div>
                    <button className='profilesecuirtysettings_button' onClick={onProfileEvent}>Security Settings</button>
                </div>
                <form className='profile_container' onSubmit={handleSubmit}>
                    <div>
                        <input type="text"
                            minLength={5}
                            name="businessname"
                            id='businessname'
                            className='profile_input'
                            value={businessName}
                            placeholder='Enter your Business Name'
                            onChange={e => setBusinessName(e.target.value)}
                        /></div>
                    <div>
                        <input type="text"
                            minLength={5}
                            name="fullname"
                            id='fullname'
                            className='profile_input'
                            value={name}
                            placeholder='Enter your Full Name'
                            onChange={e => setName(e.target.value)}
                        /></div>
                    <div>
                        <input type="text"
                            minLength={12}
                            maxLength={12}
                            name="mobilenumber"
                            id='mobilenumber'
                            className='profile_input'
                            value={mobile}
                            placeholder='Enter your Mobile Number'
                            onChange={e => setMobile(e.target.value)}
                        /></div>
                    <div>
                        <input type="text"
                            minLength={5}
                            name="emailid"
                            id='emailid'
                            className='profile_input'
                            value={email}
                            placeholder='Enter your Email id'
                            onChange={e => setEmail(e.target.value)}
                        /></div>

                    <div>
                        <input type="text"
                            minLength={5}
                            name="address"
                            id='address'
                            className='profile_input'
                            value={address}
                            placeholder='Enter your Address'
                            onChange={e => setAddress(e.target.value)}
                        /></div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <input type="text"
                                minLength={5}
                                name="city"
                                id='city'
                                className='profile_halfinput_left'
                                value={city}
                                placeholder='Enter your City'
                                onChange={e => setCity(e.target.value)}
                            /></div>

                        <div>
                            <input type="text"
                                minLength={5}
                                name="state"
                                id='state'
                                className='profile_halfinput_right'
                                value={state}
                                placeholder='Enter your State'
                                onChange={e => setState(e.target.value)}
                            /></div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <input type="text"
                                minLength={5}
                                name="country"
                                id='country'
                                className='profile_halfinput_left'
                                value={country}
                                placeholder='Enter your Country'
                                onChange={e => setCountry(e.target.value)}
                            /></div>

                        <div>
                            <input type="text"
                                minLength={5}
                                name="timezone"
                                id='timezone'
                                className='profile_halfinput_right'
                                value={timezone}
                                placeholder='Enter your Time Zone'
                                onChange={e => setTimezone(e.target.value)}
                            /></div></div>
                    <button className='profileupdate_button'>Update Profile</button>
                    <div hidden={!IsError}><span className="chatsregister_right_error">{error}</span></div>

                </form>
            </div>
        </div>
    )
}

export default Profile