import React from 'react';
import { useCountdown } from './useCountdown';
import "./CountdownTimer.css"




const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return (<div className="countdowntimer">
            <div>00</div>
            <div>00</div>
            <div>00</div>
        </div>)
    } else {
        return (
            <div className="countdowntimer">
                <div>{hours > 9 ? hours : "0" + hours}</div>
                <div>{minutes > 9 ? minutes : "0" + minutes}</div>
                <div>{seconds > 9 ? seconds : "0" + seconds}</div></div>
        );
    }
};

export default CountdownTimer;