import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./pages/Components/Login/Login"
import Register from "./pages/Components/Register/Register"
import Chats from "./pages/Components/Chats/Chats";
import Accounts from "./pages/Components/Accounts/Accounts"
import socketIO from "socket.io-client"
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import Protected from './Protected';

const socket = socketIO.connect("https://wabadev.bulkwise.in", {
    query: {
        "phid": localStorage.getItem('id')
    }
});
function App() {
    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path="/register" element={<Register socket={socket} />}></Route>
                    <Route path="/" element={<Login socket={socket} />}></Route>
                    <Route path="/chat" element={
                        <Protected >
                            <Chats socket={socket} />
                        </Protected>

                    }></Route>
                    <Route path="/accounts" element={
                        <Protected >
                            <Accounts socket={socket} />
                        </Protected>

                    }></Route>
                </Routes>
            </div>
        </BrowserRouter>

    );
}

export default App;