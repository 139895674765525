import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { validate as validateEmail } from 'email-validator';
import "./Login.css";
import axios from 'axios'

const Login = ({ socket }) => {
    const navigate = useNavigate()
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("");
    const [IsError, setIsError] = useState(false);
    const [IsMobileView, setIsMobileView] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const login = async () => {
        const input = {
            "email": userName,
            "password": password
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/user/login', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.success === true) {
                    localStorage.setItem("userName", userName)
                    //socket.emit("newUser", { userName, socketID: socket.id })
                    localStorage.setItem('login', userName);

                    localStorage.setItem('uname', res.data.uname);
                    localStorage.setItem('ubusinessname', res.data.ubusinessname);
                    localStorage.setItem('uph_number', res.data.uph_number);
                    localStorage.setItem('uemail', res.data.uemail);
                    localStorage.setItem('uaddress', res.data.address);
                    localStorage.setItem('ucity', res.data.city);
                    localStorage.setItem('ustate', res.data.state);
                    localStorage.setItem('ucountry', res.data.country);
                    localStorage.setItem('utimezone', res.data.timezone);
                    localStorage.setItem('account', JSON.stringify(res.data.account));

                    const currentAccount = res.data.account.filter(f => f.active === true);
                    console.log("RESPONSE RECEIVED:=== ", res.data.account);
                    console.log("RESPONSE RECEIVED:=== ", currentAccount);
                    if (currentAccount.length > 0) {
                        localStorage.setItem('id', currentAccount[0].id);
                        localStorage.setItem('accesstoken', currentAccount[0].accesstoken);
                    }

                   





                    navigate("/chat", { replace: true })
                } else {
                    setError(res.data.msg);
                    setIsError(true);
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }

    const handleSubmit = (e) => {
        if (userName === '') {
            e.preventDefault();
            setError("Enter your email id");
            setIsError(true);
        } else if (validateEmail(userName) === false) {
            e.preventDefault();
            setError("Enter your password");
            setIsError(true);
        } else if (password === '') {
            e.preventDefault();
            setError("Enter your password");
            setIsError(true);
        } else {
            e.preventDefault();
            login();
        }
    }
    const hrefhandle = () => {
        navigate("/register", { replace: true })
    }
    const resize = () => {
        setIsMobileView(window.innerWidth <= 768 ? true : false);
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        setIsMobileView(window.innerWidth <= 768 ? true : false);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);
    return (

        <div className="chatslogin_main">
            {!IsMobileView &&
                (<div className="chatslogin_left">
                <img className="chatslogin_left_warpper" alt="texts">

                </img>
                    {/*<div className="chatslogin_left_warpper">
                        <div className="chatslogin_left_logo">
                            <div className="chatslogin_left_logo_img"><img src={require('../../../Images/bulkwiselogo.png')} alt="test" /></div>
                            <div className="chatslogin_left_logo_span"> <span>Leading Automation Partner</span></div>
                        </div>
                    </div>*/}
                </div>)}
            <div className="chatslogin_right">
                <div className="chatslogin_right_warpper">
                    <div className="chatslogin_right_login">
                        <form className='home__container' onSubmit={handleSubmit}>
                            {IsMobileView && <img className="chatsloginlogo" alt="test"></img>}
                            <div className="chatloginwelcometext" >WELCOME BACK !!!</div>
                            <div className="chatlogintextmessage">Login your Bulkwise Account</div>
                            <div>
                                <input type="text"
                                    minLength={5}
                                    name="username"
                                    id='username'
                                    className='chatslogin_right_login_input'
                                    value={userName}
                                    placeholder='Enter your Email id'
                                    onChange={e => setUserName(e.target.value)}
                                /></div>
                            <div className="chatslogin_right_login_password_wrapper"><input type={passwordShown ? "text" : "password"}
                                minLength={5}
                                name="password"
                                id='username'
                                className='chatslogin_right_login_password'
                                value={password}
                                placeholder='Enter your Password'
                                onChange={e => setPassword(e.target.value)}
                            /><img className="chatslogin_right_login_password_img" alt="" onClick={togglePasswordVisiblity}/>
                            </div>
                            <button className='chatslogin_right_login_button'>Login</button>
                            <div className="chatloginregistermessage">Didn't Have Account ?
                                <span onClick={hrefhandle} target="_parent">  REGISTER NOW</span></div>
                            <div hidden={!IsError}><span className="chatslogin_right_error">{error}</span></div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login