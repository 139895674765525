import React from 'react'
import { Navigate } from 'react-router-dom'

function Protected({ children }) {
    let auth = localStorage.getItem('login');
    console.log("=======", auth);
    if (auth === null) {
        return <Navigate to="/" replace />
    }
    return children;
}
export default Protected;