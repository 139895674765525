// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asyncdocumentouter {
    padding: 0;
}


.asyncdocumentouter_image {
    max-width: 40vw;
    max-height: 40vh;
}


@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .asyncdocumentouter_image {
        max-width: 20vw;
        max-height: 20vh;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/AsyncDocument.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;;AAGA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA,2BAA2B,2EAA2E;IAClG;QACI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".asyncdocumentouter {\r\n    padding: 0;\r\n}\r\n\r\n\r\n.asyncdocumentouter_image {\r\n    max-width: 40vw;\r\n    max-height: 40vh;\r\n}\r\n\r\n\r\n@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */\r\n    .asyncdocumentouter_image {\r\n        max-width: 20vw;\r\n        max-height: 20vh;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
